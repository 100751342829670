import {
  Checkbox,
  Checkboxes,
  PICKUP_DISPLAY_OPTIONS,
  RadioButtons,
  theme,
  TopRoundedWhiteCard,
} from "@project/shared"
import { Col, Row } from "antd"
import { useFormik } from "formik"
import moment from "moment"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { OwnerButton } from "../../atoms"
import { DatePicker } from "../DatePicker"

interface IOperationDailyReportBulkPrint {
  facility_ids: Array<number>
  from_date: string
  to_date: string
  facilities: Array<any> | null
  onSubmit: any
}

const OperationWrapper = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  .title-wrapper {
    margin-bottom: 20px;
  }
  .ant-row {
    margin-bottom: 20px;
    align-items: center;
  }
  .caret-right {
    background: url(/assets/icons/caret-right.svg) no-repeat -3px center / 12px 12px;
    padding-left: 14px;
  }
  .flexbox {
    display: flex;
    gap: 10px;
    color: #191919;
  }
  .multiple-input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    align-items: center;
  }
  .multiple-checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .change-display-container {
    margin-top: 10px;
  }
  .custom-input {
    width: auto;
    height: 30px;
  }
  .custom-input:hover,
  .custom-input:focus {
    border-color: #d9d9d9;
  }
  .bulk-setting {
    margin-bottom: 20px;
    padding-left: 12px;
    border-left: 4px solid ${theme.blue5};
  }
  .field-error {
    display: none;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .display-change-container {
    display: flex;
    gap: 10px;
  }
  @media (max-width: 768px) {
    .ant-row {
      align-items: flex-start;
      flex-direction: column;
    }
    .operation-label {
      margin-bottom: 5px;
    }
    .operation-label,
    .operation-option {
      max-width: 100%;
    }
    .check-buttons {
      padding: 12px 20px;
    }
    .bulk-print-date-range {
      gap: 10px;
      .faculty-datepicker {
        max-width: 135px;
      }
      .ant-picker {
        max-width: 100%;
      }
    }
  }
`

const DAILY_REPORT_DISPLAY_OPTIONS = [
  {
    key: "facility",
    label: "Facility",
    value: "facility",
  },
  {
    key: "date",
    label: "Date",
    value: "date",
  },
  {
    key: "entry",
    label: "Entry Person",
    value: "entry",
  },
  {
    key: "manager",
    label: "Facility Manager Confirmation",
    value: "manager",
  },
  {
    key: "approval",
    label: "Approval",
    value: "approval",
  },
  {
    key: "service",
    label: "Service Used",
    value: "service",
  },
  {
    key: "attendance",
    label: "Attendance",
    value: "attendance",
  },
  {
    key: "provision",
    label: "Form of provision",
    value: "provision",
  },
  {
    key: "enter_exit",
    label: "Entering and exiting the room",
    value: "enter_exit",
  },
  {
    key: "temperature",
    label: "Body temperature upon entry",
    value: "temperature",
  },
  {
    key: "actual_cost",
    label: "Actual Cost ",
    value: "actual_cost",
  },
  {
    key: "pickup",
    label: "Pick up destination",
    value: "pickup",
  },
  {
    key: "service_content",
    label: "Service Content",
    value: "service_content",
  },
  {
    key: "content",
    label: "Content",
    value: "content",
  },
  {
    key: "image",
    label: "Image file",
    value: "image",
  },
  {
    key: "staff",
    label: "Worker column",
    value: "staff",
  },
]

export const OperationDailyReportBulkPrint = ({
  facility_ids,
  from_date,
  to_date,
  facilities,
  onSubmit = null,
}: IOperationDailyReportBulkPrint) => {
  const { t } = useTranslation()

  const initialValues = {
    facility_ids,
    from_date,
    to_date,
    display_contents: [
      "facility",
      "attendance",
      "pickup",
      "date",
      "activity",
      "entry",
      "enter_exit",
      "content",
      "temperature",
      "service",
      "actual_cost",
      "manager",
    ],
    pickup_display_style: 2,
  }

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      onSubmit(formik.values)
    },
  })

  return (
    <OperationWrapper>
      <TopRoundedWhiteCard title={t("Operation Options")}>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Facility")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container mb-10"}>
              <OwnerButton
                typeOf={"check-all"}
                text={t("Check all")}
                className={"check-buttons"}
                onClick={() => {
                  const allFacilityId = facilities?.map(
                    (facility) => facility?.value
                  )
                  formik.setFieldValue("facility_ids", allFacilityId)
                }}
              />
              <OwnerButton
                typeOf={"secondary"}
                text={t("Uncheck all")}
                className={"check-buttons"}
                onClick={() => {
                  formik.setFieldValue("facility_ids", [])
                }}
              />
            </div>
            <Checkboxes
              options={facilities}
              value={formik.values.facility_ids}
              name={"facility_ids"}
              onChange={(val) => formik.setFieldValue("facility_ids", val)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Date narrow down")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-input-container bulk-print-date-range"}>
              <DatePicker
                onDateChange={(val) => {
                  formik.setFieldValue(
                    "from_date",
                    moment(val).isValid()
                      ? moment(val, "YYYY-MM-DD")
                      : moment(new Date(), "YYYY-MM-DD")
                  )
                }}
                style={{ height: "40px", width: "160px" }}
                date={
                  formik.values.from_date
                    ? moment(formik.values.from_date)
                    : null
                }
                format={"YYYY年MM月DD日"}
              />
              {"〜 "}
              <DatePicker
                onDateChange={(val) => {
                  formik.setFieldValue(
                    "to_date",
                    moment(val).isValid()
                      ? moment(val, "YYYY-MM-DD")
                      : moment(new Date(), "YYYY-MM-DD")
                  )
                }}
                style={{ height: "40px", width: "160px" }}
                date={
                  formik.values.to_date ? moment(formik.values.to_date) : null
                }
                format={"YYYY年MM月DD日"}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Display contents")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <div className={"multiple-checkboxes"}>
              {DAILY_REPORT_DISPLAY_OPTIONS.map((option) => (
                <Checkbox
                  key={option.key}
                  label={t(option.label)}
                  value={option.value}
                  name={"display_contents"}
                  onChange={formik.handleChange}
                  checked={formik.values.display_contents.includes(
                    option.value
                  )}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} className={"caret-right operation-label"}>
            {t("Display pick-up")}
          </Col>
          <Col span={20} className={"operation-option"}>
            <RadioButtons
              options={PICKUP_DISPLAY_OPTIONS}
              value={formik.values.pickup_display_style}
              name={"pickup_display_style"}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <div className={"display-change-container"}>
          <OwnerButton
            typeOf={"secondary"}
            text={t("Change display")}
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </TopRoundedWhiteCard>
    </OperationWrapper>
  )
}
