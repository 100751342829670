import { API } from "@project/shared"
interface Args {
  program_category_name: string
}

export interface ProgramCategoryResponseObj {
  company_id?: number
  created_by?: string
  created_datetime: string
  deleted_by?: string
  deleted_datetime?: string | null
  id: number
  is_deleted?: boolean
  program_category_name: string
  updated_by?: string | null
  updated_datetime?: string | null
}

export type ProgramDataProps = Array<
  {
    company_id: number
    program_category_id: number
    program_name: string
    ProgramCategory: {
      id: number
      created_datetime: string
      created_by: string | null
      updated_datetime: string
      updated_by: string | null
      deleted_datetime: string | null
      deleted_by: string | null
      is_deleted: boolean
      company_id: number
      program_category_name: string
      programs: ProgramDataProps | null
    }
  } & API.GenericResponse
>

export type ProgramCategoryData = {
  company_id: number
  program_category_name: string
  programs: ProgramDataProps
} & API.GenericResponse

export type AllProgramCategoryResponse = {
  count: number
  data: Array<ProgramCategoryData>
}

export const createProgramCategory = (values: Args) =>
  API.post(`/program_categories`, { ...values })

export const updateProgramCategory = ({
  values,
  id,
}: {
  values: Args
  id: string
}) => API.put(`/program_categories/${id}`, { ...values })

export const deleteProgramCategory = (id: string) =>
  API.delete(`/program_categories/${id}`)

export const fetchProgramCategories = ({
  page,
  pageSize,
}: {
  page: number
  pageSize: number | string
}) => API.get(`/program_categories?page=${page}&pageSize=${pageSize}`)

export const fetchAllProgramCategories =
  (): Promise<AllProgramCategoryResponse> => API.get(`/program_categories`)

export const fetchProgramCategory = (id: string) =>
  API.get(`/program_categories/${id}`)
