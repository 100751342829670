import { t } from "i18next"
import Link from "next/link"
import styled from "styled-components"
import { getServiceColor, getServiceInitial } from "../../../utils/facility"
import { theme } from "@project/shared"

const StyledHoliday = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(224, 0, 0, 0.1);
  color: #e00000;
  font-weight: bold;
  padding-top: 24px;
  font-size: 14px;
  min-height: 100px;
  @media print {
    color: ${theme.black};
  }
`

const StyledAttendanceList = styled.div`
  padding: 40px 5px 20px 5px;
  width: 100%;
  &.cursor-auto {
    cursor: auto;
  }
  .grouped-container {
    margin-bottom: 20px;
    a {
      &:hover {
        color: #000000;
      }
    }
  }
  .tag-value {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    div:not(:last-child) {
      margin-right: 4px;
    }
    &.wrapped {
      flex-wrap: wrap;
    }
  }
  .tag {
    padding: 4px;
    border-radius: 5px;
    font-size: 12px;
    min-width: 32px;
  }
  .value {
    color: #0782c8;
    font-size: 14px;
    text-align: left;
  }
  .with-underline {
    text-decoration: underline;
    @media print {
      text-decoration: none;
      color: ${theme.black};
    }
  }
  .grey-tag {
    background: #d2d1d1;
  }
  .blue-tag {
    background: #4da2f8;
    color: #ffffff;
  }
  .activity-label {
    width: 100%;
    margin-bottom: 10px;
    background-color: #eeeeee;
    border-radius: 5px;
    padding: 4px;
    text-align: center;
    color: #e7450b;
    @media print {
      color: ${theme.black};
    }
  }
  .schedule-record {
    display: grid;
    grid-row-gap: 0px;
    grid-column-gap: 4px;
    max-width: 100%;
    grid-template:
      "service-name child-name"
      "nothing pick-drop";
    grid-template-columns: 1fr 3fr;
  }
  .schedule-record-label {
    grid-area: service-name;
  }
  .schedule-record-value {
    grid-area: child-name;
  }
  .schedule-pick-drop-container {
    margin-top: 4px;
    display: flex;
    grid-area: pick-drop;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 2px;
  }
  .schedule-pick-drop-container span {
    font-size: 14px;
    color: #000000;
  }
  .schedule-pick-drop-container > span {
    display: flex;
    gap: 4px;
  }
  .schedule-pick-drop-container > span:first-child {
    margin-right: 4px;
  }
  .checked-pickup .pick-drop-label {
    border-radius: 3px;
    background: #79e3aa;
    color: #ffffff;
    padding: 0 3px;
  }
  .checked-drop .pick-drop-label {
    border-radius: 3px;
    background: #4da2f8;
    color: #ffffff;
    padding: 0 3px;
  }
  .unchecked-pickup .pick-drop-label,
  .unchecked-drop .pick-drop-label {
    background: #d2d1d1;
    border-radius: 3px;
    padding: 0 3px;
  }

  @media print {
    .activity-label,
    .schedule-record-label,
    .schedule-record-value,
    .tag,
    .value,
    .pick-drop-label,
    .pick-drop-value {
      font-size: 0.85em !important;
      span {
        font-size: 0.85em !important;
      }
    }
    .tag-value {
      .tag {
        font-size: 0.85em;
        padding: 2px;
        min-width: 26px;
      }
      .value {
        font-size: 0.85em;
        word-break: break-all;
        color: ${theme.black};
      }
    }
    .grouped-container {
      margin-bottom: 10px;
    }
  }
`

export const AttendanceDate = ({
  className = "",
  daySchedule,
  activityVisibility,
  commuterVisibility,
  pickUpDropOffVisibility,
  facilityId,
  disableLink = false,
}) => {
  if (daySchedule && daySchedule.is_holiday == "1")
    return <StyledHoliday className={className}>{t("holiday")}</StyledHoliday>

  const showCommuter = +commuterVisibility
  const showActivity = +activityVisibility
  const showPicUpDropOff = +pickUpDropOffVisibility == 1

  const activityList =
    showActivity && daySchedule && Array.isArray(daySchedule.activities)
      ? daySchedule.activities
      : []

  const scheduleList =
    daySchedule && Array.isArray(daySchedule.child_facility_schedule)
      ? daySchedule.child_facility_schedule
      : []

  const scheduleCount =
    scheduleList && Array.isArray(scheduleList) ? scheduleList.length : 0

  const absentList =
    daySchedule && Array.isArray(daySchedule.absent_children)
      ? daySchedule.absent_children
      : []

  const absentCount = absentList.length

  const absentNoAdditionList =
    daySchedule && Array.isArray(daySchedule.absent_no_addition_children)
      ? daySchedule.absent_no_addition_children
      : []

  const absentNoAdditionCount = absentNoAdditionList.length

  const experienceList =
    daySchedule && Array.isArray(daySchedule.experience_children)
      ? daySchedule.experience_children
      : []

  const experienceCount = experienceList.length

  const unregisteredList =
    daySchedule && Array.isArray(daySchedule.unregistered_children)
      ? daySchedule.unregistered_children
      : []

  const unregisteredCount = unregisteredList.length

  const commuterList =
    showCommuter && daySchedule && Array.isArray(daySchedule.commuters)
      ? daySchedule.commuters
      : []

  const commuterCount = commuterList.length

  const displayAttendance = () => (
    <>
      <div className={"tag-value wrapped"}>
        <div className={"tag grey-tag"}>{t("Attendance  ")}</div>
        <span className={"value with-underline"}>
          {`${scheduleCount}${t("people")}`}
        </span>
      </div>
      {scheduleList.map((scheduled) => (
        <div
          className={"tag-value schedule-record"}
          key={scheduled?.child?.child_id}
        >
          {scheduled?.child?.child_service_type && (
            <div
              className={"tag blue-tag schedule-record-label"}
              style={{
                background: getServiceColor(
                  scheduled?.child?.child_service_type?.toString()
                ),
              }}
            >
              {t(
                getServiceInitial(
                  scheduled?.child?.child_service_type?.toString()
                )
              )}
            </div>
          )}
          <div className={"value with-underline schedule-record-value"}>
            {scheduled?.child?.child_name}
          </div>
          {(scheduled?.desired_pickup_time != "" ||
            scheduled?.desired_drop_time != "") &&
            showPicUpDropOff && (
              <div className={"schedule-pick-drop-container"}>
                {scheduled?.desired_pickup_time != "" && (
                  <span
                    className={
                      scheduled.is_pickup == "1"
                        ? "checked-pickup"
                        : "unchecked-pickup"
                    }
                  >
                    <span className={"pick-drop-label"}>{t("Pick")}</span>
                    <span className={"pick-drop-value"}>
                      {scheduled.desired_pickup_time}
                    </span>
                  </span>
                )}
                {scheduled?.desired_drop_time != "" && (
                  <span
                    className={
                      scheduled.is_drop == "1"
                        ? "checked-drop"
                        : "unchecked-drop"
                    }
                  >
                    <span className={"pick-drop-label"}>{t("Drop")}</span>
                    <span className={"pick-drop-value"}>
                      {scheduled.desired_drop_time}
                    </span>
                  </span>
                )}
              </div>
            )}
        </div>
      ))}
    </>
  )

  const displayAbsent = () => (
    <>
      <div className={"tag-value wrapped"}>
        <div className={"tag grey-tag"}>{t("Absence")}</div>
        <span className={"value with-underline"}>
          {`${absentCount}${t("people")}`}
        </span>
      </div>
      {absentList.map((absent) => (
        <div className={"tag-value"} key={absent?.child_id}>
          {absent?.child_service_type && (
            <div
              className={"tag blue-tag"}
              style={{
                background: getServiceColor(
                  absent?.child_service_type?.toString()
                ),
              }}
            >
              {t(getServiceInitial(absent?.child_service_type?.toString()))}
            </div>
          )}
          <span className={"value with-underline"}>{absent?.child_name}</span>
        </div>
      ))}
    </>
  )

  const displayAbsentNoAddition = () => (
    <>
      <div className={"tag-value wrapped"}>
        <div className={"tag grey-tag"}>{t("absentNoAddition")}</div>
        <span className={"value with-underline"}>
          {`${absentNoAdditionCount}${t("people")}`}
        </span>
      </div>
      {absentNoAdditionList.map((absent) => (
        <div className={"tag-value"} key={absent?.child_id}>
          {absent?.child_service_type && (
            <div
              className={"tag blue-tag"}
              style={{
                background: getServiceColor(
                  absent?.child_service_type?.toString()
                ),
              }}
            >
              {t(getServiceInitial(absent?.child_service_type?.toString()))}
            </div>
          )}
          <span className={"value with-underline"}>{absent?.child_name}</span>
        </div>
      ))}
    </>
  )

  const displayExperience = () => (
    <>
      <div className={"tag-value wrapped"}>
        <div className={"tag grey-tag"}>{t("Experience")}</div>
        <span className={"value with-underline"}>
          {`${experienceCount}${t("people")}`}
        </span>
      </div>
      {experienceList.map((exp) => (
        <div className={"tag-value"} key={exp?.child?.child_id}>
          {exp?.child_service_type && (
            <div
              className={"tag blue-tag"}
              style={{
                background: getServiceColor(
                  exp?.child?.child_service_type?.toString()
                ),
              }}
            >
              {t(getServiceInitial(exp?.child_service_type?.toString()))}
            </div>
          )}
          <span className={"value with-underline"}>{exp?.child_name}</span>
        </div>
      ))}
    </>
  )

  const displayUnregistered = () => (
    <>
      <div className={"tag-value wrapped"}>
        <div className={"tag grey-tag"}>{t("Unregistered")}</div>
        <span className={"value with-underline"}>
          {`${unregisteredCount}${t("people")}`}
        </span>
      </div>{" "}
      {unregisteredList.map((unregistered) => (
        <div className={"tag-value"} key={unregistered?.child_id}>
          {unregistered?.child_service_type && (
            <div
              className={"tag blue-tag"}
              style={{
                background: getServiceColor(
                  unregistered?.child_service_type?.toString()
                ),
              }}
            >
              {t(
                getServiceInitial(unregistered?.child_service_type?.toString())
              )}
            </div>
          )}
          <span className={"value with-underline"}>
            {unregistered?.child_name}
          </span>
        </div>
      ))}
    </>
  )

  const displayCommuter = () => (
    <>
      <div className={"tag-value wrapped"}>
        <div className={"tag grey-tag"}>{t("出勤スタッフ")}</div>
        <span className={"value with-underline"}>
          {`${commuterCount}${t("people")}`}
        </span>
      </div>{" "}
      {commuterList.map((commuter, index) => {
        return (
          <div className={"tag-value"} key={index}>
            <span className={"value"}>{commuter}</span>
          </div>
        )
      })}
    </>
  )

  return (
    <StyledAttendanceList
      className={`${className} ${disableLink ? "cursor-auto" : ""}`}
    >
      {activityList.map(
        (activity, index) =>
          activity !== "" && (
            <div className={"activity-label"} key={index}>
              {t(activity)}
            </div>
          )
      )}
      <div className={"grouped-container"}>
        {disableLink ? (
          displayAttendance()
        ) : (
          <Link
            href={`/user-attendance-table/day-list?facilityId=${facilityId}&date=${daySchedule.formattedDate}`}
          >
            <a>{displayAttendance()}</a>
          </Link>
        )}
      </div>

      {absentCount > 0 && (
        <div className={"grouped-container"}>
          {disableLink ? (
            displayAbsent()
          ) : (
            <Link
              href={`/user-attendance-table/day-list?facilityId=${facilityId}&date=${daySchedule.formattedDate}`}
            >
              <a>{displayAbsent()}</a>
            </Link>
          )}
        </div>
      )}
      {absentNoAdditionCount > 0 && (
        <div className={"grouped-container"}>
          {disableLink ? (
            displayAbsentNoAddition()
          ) : (
            <Link
              href={`/user-attendance-table/day-list?facilityId=${facilityId}&date=${daySchedule.formattedDate}`}
            >
              <a>{displayAbsentNoAddition()}</a>
            </Link>
          )}
        </div>
      )}
      {experienceCount > 0 && (
        <div className={"grouped-container"}>
          {disableLink ? (
            displayExperience()
          ) : (
            <Link
              href={`/user-attendance-table/day-list?facilityId=${facilityId}&date=${daySchedule.formattedDate}`}
            >
              <a>{displayExperience()}</a>
            </Link>
          )}
        </div>
      )}
      {unregisteredCount > 0 && (
        <div className={"grouped-container"}>
          {disableLink ? (
            displayUnregistered()
          ) : (
            <Link
              href={`/user-attendance-table/day-list?facilityId=${facilityId}&date=${daySchedule.formattedDate}`}
            >
              <a>{displayUnregistered()}</a>
            </Link>
          )}
        </div>
      )}
      {showCommuter > 0 && (
        <div className={"grouped-container"}>
          {disableLink ? (
            displayCommuter()
          ) : (
            <Link
              href={`/staff-attendance/detail?facilityIds=${facilityId}&date=${daySchedule.formattedDate}`}
            >
              <a>{displayCommuter()}</a>
            </Link>
          )}
        </div>
      )}
    </StyledAttendanceList>
  )
}
